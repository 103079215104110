import axios from 'axios';
import {
  TokenService
} from '@/services/storage.service'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export let HTTP = axios.create({
  headers: {
    Authorization: 'Token ' + TokenService.getToken()
  }
});