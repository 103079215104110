<template>
  <footer class="footer footer pa--lg-8 pa-lg-5 pa-3">
    <!-- footer -->
    <div class="row justify-content-center justify-content-lg-start">
      <div class="footer-logo pb-3 mx-auto ml-lg-0">
        <img
          src="https://qnt.com.co/wp-content/uploads/2021/07/qnt-logo-gris.png"
          alt=""
        >
      </div>
    </div>
    <section class="
          row
          align-start">
      <div class="col-12 col-lg-6">
        <div class="column-inner ">
          <div class="d-none d-lg-block">
            <p>
              Qnt, es la primera y única empresa de Rebancarización en donde brindamos a nuestros clientes acompañamiento, para que salgan de deudas y vuelvan al sistema financiero.</p>
            <p>Creemos firmemente que nuestros clientes merecen la oportunidad de recuperar por completo su tranquilidad y el control sobre sus finanzas para alcanzar sus metas.</p>
          </div>
          <div class="row text-center text-lg-left mt-lg-8">
            <div class="col-12 col-lg-6 py-0">
              <div class="column-inner">
                <div class="wpb_wrapper">
                  <div class="wpb_text_column wpb_content_element ">
                    <div class="wpb_wrapper">
                      <p class="call-text"><i
                          class="fa fa-phone"
                          aria-hidden="true"
                          style="padding-right: 10px;"
                        ></i>PBX: (+60 1) 918 3131</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 py-0">
              <div class="column-inner">
                <div class="wpb_wrapper">
                  <div class="wpb_text_column wpb_content_element ">
                    <div class="wpb_wrapper">
                      <p class="call-text"><a
                          href="https://api.whatsapp.com/send?phone=573212394360"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            class="alignnone size-full icono-wp.png"
                            src="https://qnt.com.co/wp-content/uploads/2024/01/icono-wp.png"
                            alt=""
                            width="32"
                            height="32"
                          >&nbsp; 321 239 4360</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 d-none d-lg-flex qnt-cert">
        <section class="row row-fluid align-center w-100">
          <div class="col col-sm-6">
            <img
              class="alignnone size-full wp-image-309635 footer-logo"
              src="https://qnt.com.co/wp-content/uploads/2020/12/logo-colombia_fintech-300x227-1.png"
              alt=""
            >
          </div>
          <div class="col col-sm-6">
            <img
              class="alignnone size-full wp-image-51055 footer-logo"
              src="https://qnt.com.co/wp-content/uploads/2019/09/logo-ccce.png"
              alt=""
            >
          </div>
        </section>
      </div>
    </section>

  </footer>
</template>

<script> 
export default {
  name: 'Footer'
}
</script>

<style lang="scss">
.footer {
  background-color: #1e2127;
  color: #ccc;
  .qnt-cert img {
    width: 200px;
  }
  &-logo img {
    height: 60px;
  }
}
</style>
