import {
  HTTP
} from './http-common';

export default {
  updateGeneralInformation(data) {
    return HTTP.patch('/qnt/user/general/', data);
  },
  updateFinanceInformation(data) {
    return HTTP.post('/qnt/user/finance/', data);
  },
  getInformation(data) {
    return HTTP.get('/qnt/user/', data);
  },
  updatePlanDefault(data) {
    return HTTP.post('/qnt/user/default/plan/', data);
  },
  updateMobileTC(data) {
    return HTTP.post('/qnt/user/mobile_tc/', data);
  },
  getPreferredLocation(data) {
    return HTTP.get('/qnt/user/locations/preferred/', data);
  }
}