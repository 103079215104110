import { HTTP } from './http-common';
import { BASE_URL } from './otp.service';

export default {
  getInfoCreditCard(data) {
    return HTTP.post(`${BASE_URL}/DetalleTarjeta`,data)
  },

  getCreditCardIdentification(external_id){
    return HTTP.get(`${BASE_URL}/tarjeta/contrato_centroAlta`, {
      params: {
        externalId: external_id
      }
    })
  },
  getArreasBalanceRP(contrato) {
    return HTTP.get(`${BASE_URL}/tarjeta/saldoMoraRP`, {
      params: {
        contrato
      }
    })
  }

}
